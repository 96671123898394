import React, { useState, useEffect, useRef } from "react";
// import { useLocation } from 'react-router-dom';

import {Card, CardHeader, CardBody, CardFooter, Link} from "@nextui-org/react";
import {Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip, Dropdown, DropdownMenu, DropdownTrigger, DropdownItem} from "@nextui-org/react";


import { SiGitbook } from "react-icons/si";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import { IoChevronDownSharp } from "react-icons/io5";

import { useAuth } from "../context/auth_context";
import { ProfileAvatar } from "../profile_avatar";

export function Navbar_() {
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData
    } = useAuth()

    // const location = useLocation();
    // const showLogin = location.pathname === '/pricing' || 
    //     location.pathname === '/dashboard' ||
    //     location.pathname === '/playground'

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {/* <Card radius="none" className="bg-background border-small border-divider">
                <CardBody className="px-6 max-w-5xl mx-auto">
                    TOTO API has just launched! Create an API account for free.
                </CardBody>
            </Card> */}

            <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
                <NavbarContent>
                    <NavbarMenuToggle
                        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                        className="sm:hidden px-4"
                    />

                    <NavbarBrand>
                        {isMobile ? (
                            <Link href="/">
                                <Image
                                    alt="toto logo"
                                    height={62}
                                    radius="none"
                                    // src="/toto_api_wordart.png"
                                    src="/logos/toto_wordart.png"
                                    // src="/toto_fire.gif"
                                    width={62}
                                />
                            </Link>
                        ) : (
                            <Link className="font-bold text-inherit" href="/">TOTO API</Link>
                        )}
                    </NavbarBrand>
                </NavbarContent>

                <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    <NavbarItem>
                        <Link color="foreground" href="/dashboard">Dashboard</Link>
                    </NavbarItem>

                    <NavbarItem>
                        <Link color="foreground" href="/playground">Playground</Link>
                    </NavbarItem>

                    <NavbarItem>
                        <Link color="foreground" href="/pricing">Pricing</Link>
                    </NavbarItem>

                    <Tooltip content="Coming Soon">
                        <NavbarItem>
                            <Link color="foreground" href="" isDisabled>Status</Link>
                        </NavbarItem>
                    </Tooltip>

                    {/* <Dropdown backdrop="blur">
                        <DropdownTrigger>
                            <Button
                                disableRipple
                                className="p-0 bg-transparent data-[hover=true]:bg-transparent text-md text-foreground"
                                endContent={
                                    <svg
                                        fill="none"
                                        height={16}
                                        viewBox="0 0 24 24"
                                        width={16}
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <path
                                            d="m19.92 8.95-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
                                            stroke={"white"}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeMiterlimit={10}
                                            strokeWidth={1.5}
                                        />
                                    </svg>
                                }
                                radius="sm"
                                variant="light"
                            >
                                Free Tools
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Link Actions" disabledKeys={["2", "3"]}>
                            <DropdownItem
                                description="Convert Twitter username to id and vice versa"
                                // startContent={<CopyDocumentIcon className={iconClasses} />}
                                key="1"
                                as={Link}
                                href="/converter"
                                className="text-foreground"
                            >
                                Converter
                            </DropdownItem>
                            <DropdownItem
                                description="...."
                                // startContent={<CopyDocumentIcon className={iconClasses} />}
                                key="2"
                                // href=""
                                className="text-foreground"
                            >
                                Coming Soon...
                            </DropdownItem>
                            <DropdownItem
                                description="..."
                                // startContent={<CopyDocumentIcon className={iconClasses} />}
                                key="3"
                                // href=""
                                className="text-foreground"
                            >
                                Coming Soon...
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown> */}
                </NavbarContent>

                <NavbarContent justify="end">
                    <div className="hidden sm:flex gap-1">
                        <Tooltip content="Twitter">
                            <NavbarItem>
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://twitter.com/toto_api" isExternal={true} isIconOnly aria-label="Twitter">
                                        <FaXTwitter />
                                    </Button> 
                            </NavbarItem>
                        </Tooltip>

                        <Tooltip content="Telegram">
                            <NavbarItem>
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://t.co/KiaAdQq7hV" isExternal={true} isIconOnly aria-label="Telegram">
                                        <FaTelegramPlane />
                                    </Button> 
                            </NavbarItem>
                        </Tooltip>

                        <Tooltip content="Gitbook">
                            <NavbarItem>
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://docs.totoml.app" isExternal={true} isIconOnly aria-label="Gitbook">
                                        <SiGitbook />
                                    </Button> 
                            </NavbarItem>
                        </Tooltip>
                    </div>

                    <NavbarItem>
                        {signedIn ? (
                            // <Button as={Link} href="/profile" size="sm" color="primary">Profile</Button>
                            <ProfileAvatar />
                        ) : (
                            <Button as={Link} href="/login" size="sm" color="primary">Login</Button>
                        )}
                    </NavbarItem>
                </NavbarContent>

                <NavbarMenu className="z-50">
                    <div className="mb-4">
                        <p>Services</p>

                        <NavbarMenuItem>
                            <Link className="text-default-500" href="/dashboard" size="lg">Dashboard</Link>
                        </NavbarMenuItem>

                        <NavbarMenuItem>
                            <Link className="text-default-500" href="/playground" size="lg">Playground</Link>
                        </NavbarMenuItem>

                        <NavbarMenuItem>
                            <Link className="text-default-500" href="/pricing" size="lg">Pricing</Link>
                        </NavbarMenuItem>

                        <NavbarMenuItem>
                            <Link className="text-default-500" href="/" size="lg" isDisabled>Status</Link>
                        </NavbarMenuItem>
                    </div>

                    <div>
                        <p>Socials</p>

                        <NavbarMenuItem>
                            <Link className="text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://twitter.com/toto_api">Twitter</Link>
                        </NavbarMenuItem>

                        <NavbarMenuItem>
                            <Link className="text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.co/KiaAdQq7hV">Telegram</Link>
                        </NavbarMenuItem>

                        <NavbarMenuItem>
                            <Link className="text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.totoml.app">Gitbook</Link>
                        </NavbarMenuItem>
                    </div>
                </NavbarMenu>
            </Navbar>
        </>
    );
}