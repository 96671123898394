import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

// import { CustomSpinner } from "../../components/custom_spinner";
import {Link} from "@nextui-org/react";
import {Avatar, AvatarIcon, AvatarGroup, Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip} from "@nextui-org/react";
import {
    Table,
    TableHeader,
    TableBody,
    TableColumn,
    TableRow,
    TableCell
} from "@nextui-org/table";
import {Divider} from "@nextui-org/divider";
import {Accordion, AccordionItem} from "@nextui-org/accordion";

import { Navbar_ } from "../components/landing_page/navbar";

import { SiGitbook } from "react-icons/si";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Faq } from "../components/landing_page/faq";
import { Footer } from "../components/landing_page/footer";


export function Index() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // useEffect(() => {
    // }, []);

    return (
        <>
            <Navbar_ />

            <div className="p-6 mx-auto max-w-5xl ">
                {/* <Card className="flex flex-col sm:flex-row items-center justify-center max-w-5xl mx-auto bg-background border-small border-divider p-6"> */}
                <Card className="flex flex-col sm:flex-row items-center justify-center max-w-5xl mx-auto bg-background mt-4">
                    <div className="mb-4 sm:mb-0 sm:mr-6">
                        <Image
                            alt="divider image"
                            height={150}
                            radius="sm"
                            src="/landing_page_images/group_3.svg"
                            width={150}
                        />
                    </div>
                    <div className="grid max-w-xs w-full sm:w-auto">
                        <div className="w-full flex justify-center sm:justify-start">
                            <AvatarGroup 
                                max={6} 
                                total={100} 
                                radius="sm" 
                                size="sm" 
                                className="gap-2"
                                renderCount={(count) => (
                                    // <p className="text-small text-foreground font-medium ms-2">+{count} others</p>
                                    <p className="text-small text-foreground font-medium ms-2">and {count}+ others</p>
                                )}
                            >
                                {/* <Avatar color="primary" src="/landing_page_images/portrait_1.jpg" />
                                <Avatar color="primary" src="/landing_page_images/portrait_2.jpg" />
                                <Avatar color="primary" src="/landing_page_images/portrait_3.jpg" />
                                <Avatar color="primary" src="/landing_page_images/portrait_4.jpg" />
                                <Avatar color="primary" src="/landing_page_images/portrait_5.jpg" />
                                <Avatar color="primary" src="/landing_page_images/portrait_6.jpg" /> */}
                                <Avatar
                                    icon={<AvatarIcon />}
                                    classNames={{
                                        base: "bg-gradient-to-br from-black to-gray-800",
                                        icon: "text-purple-500",
                                        // icon: "text-white"
                                    }}
                                />
                                <Avatar
                                    icon={<AvatarIcon />}
                                    classNames={{
                                        base: "bg-gradient-to-br from-black to-gray-800",
                                        icon: "text-red-500",
                                    }}
                                />
                                <Avatar
                                    icon={<AvatarIcon />}
                                    classNames={{
                                        base: "bg-gradient-to-br from-black to-gray-800",
                                        icon: "text-green-500",
                                    }}
                                />
                                <Avatar
                                    icon={<AvatarIcon />}
                                    classNames={{
                                        base: "bg-gradient-to-br from-black to-gray-800",
                                        icon: "text-yellow-500",
                                    }}
                                />
                                <Avatar
                                    icon={<AvatarIcon />}
                                    classNames={{
                                        base: "bg-gradient-to-br from-black to-gray-800",
                                        icon: "text-blue-500",
                                    }}
                                />
                                <Avatar
                                    icon={<AvatarIcon />}
                                    classNames={{
                                        base: "bg-gradient-to-br from-black to-gray-800",
                                        icon: "text-orange-500",
                                    }}
                                />
                            </AvatarGroup>
                        </div>
                        <div className="">
                            <p className="mt-1 text-xs text-default-500 break-words hyphens-auto text-center sm:text-left">
                                {/* Join these satisfied customers in getting the exact data they need, when they need it. */}
                                Join these satisfied customers and get the exact data you need, when you need it.
                            </p>
                        </div>
                    </div>
                </Card>

                <Card className="bg-background bg-no-repeat bg-cover mt-10" style={{ backgroundImage: 'url("/landing_page_images/noisy-gradients.png")' }}>
                {/* <Card className="bg-background border-small border-divider mt-10"> */}
                {/* <Card className="bg-background mt-10"> */}
                    <CardHeader className="flex flex-col items-start">
                        {/* <p className="text-5xl">Twitter Insights</p>
                        <p className="text-5xl">at a Fraction of the Price.</p> */}
                        <p className="text-4xl sm:text-5xl">Insights,</p>
                        <p className="text-4xl sm:text-5xl">at a Fraction of the Price.</p>
                    </CardHeader>
                    <CardBody>
                        {/* <p className="text-md text-default-500">The API to use when accessing Crypto Twitter related data.</p>
                        <p className="text-md text-default-500">Find, verify, and confirm any account at scale.</p> */}
                        <p className="text-md text-default-500 w-full sm:max-w-md">The Go-to API for Crypto Twitter and On-Chain Data. Effortlessly Find, Verify, and Analyze Anything, at Scale.</p>
                        {/* <p className="text-md text-default-500">Effortlessly Find, Verify, and Analyze Accounts at Scale.</p> */}
                    </CardBody>
                    <CardFooter>
                        <div className="w-full space-y-2 sm:space-y-0 sm:space-x-2">
                            <Button as={Link} href="/login" color="primary" className="w-full sm:w-auto">Get Started for Free</Button>
                            <Button as={Link} href="/pricing" variant="flat" className="w-full sm:w-auto">View Pricing</Button>
                        </div>
                    </CardFooter>
                </Card>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-10">
                    <Card 
                        className="w-full bg-background border-small border-divider" 
                        // className="w-full" 
                        as={Link}
                        href="https://docs.totoml.app/authentication"
                        isExternal={true}
                    >
                        <CardBody className="">
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-between">
                                    <p className="text-xl">Getting Started</p>
                                    <span className="">{'↗'}</span>
                                </div>
                                <p className="text-sm text-default-500">Discover how to obtain a TOTO API key and make your first request.</p>
                            </div>
                        </CardBody>
                    </Card>

                    <Card 
                        className="w-full bg-background border-small border-divider" 
                        // className="w-full" 
                        as={Link}
                        href="https://docs.totoml.app/credit-values"
                        isExternal={true}
                    >
                        <CardBody className="">
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-between">
                                    <p className="text-xl">Credit Values</p>
                                    <span className="">{'↗'}</span>
                                </div>
                                <p className="text-sm text-default-500">View the cost per successful request for each TOTO API endpoint.</p>
                            </div>
                        </CardBody>
                    </Card>

                    <Card 
                        className="w-full bg-background border-small border-divider" 
                        // className="w-full" 
                        as={Link}
                        href="https://docs.totoml.app/api-reference"
                        isExternal={true}
                    >
                        <CardBody className="">
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-between">
                                    <p className="text-xl">API Reference</p>
                                    <span className="">{'↗'}</span>
                                </div>
                                <p className="text-sm text-default-500">View the TOTO API reference documentation.</p>
                            </div>
                        </CardBody>
                    </Card>

                    <Card 
                        className="w-full bg-background border-small border-divider" 
                        // className="w-full" 
                        as={Link}
                        href="https://t.co/KiaAdQq7hV"
                        isExternal={true}
                    >
                        <CardBody className="">
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-between">
                                    <p className="text-xl">Issues</p>
                                    <span className="">{'↗'}</span>
                                </div>
                                {/* <p className="text-sm text-default-500">Report a bug, issues you've encountered, or get answers to any questions you might have.</p> */}
                                <p className="text-sm text-default-500">Report bugs and issues with the TOTO API.</p>
                            </div>
                        </CardBody>
                    </Card>
                </div>

                {/* <div className="relative overflow-hidden bg-no-repeat bg-cover rounded-large" 
                    style={{ backgroundImage: 'url("/landing_page_images/rectangle8.png")'}}>
                    <div className="relative z-10 p-4">
                        <h1 className="text-5xl">The Information You Want,</h1>
                        <h1 className="text-5xl">When You Want It.</h1>

                        <div className="mt-4">
                            <p className="text-md text-default-500">The API to use when accessing Crypto Twitter related data.</p>
                            <p className="text-md text-default-500">Find, verify, and confirm any account at scale.</p>
                        </div>

                        <div className="mt-4 space-x-2">
                            <Button as={Link} color="secondary" href="/pricing">Pricing</Button>
                            <Button as={Link} variant="light" href="/pricing">Try It Free*</Button>
                        </div>
                    </div>
                </div> */}

                {/* <div className="relative z-10">
                    <h1 className="text-5xl">The information you want,</h1>
                    <h1 className="text-5xl">when you want it.</h1>

                    <div className="mt-4">
                        <p className="text-md text-default-500">The API to use when accessing Crypto Twitter related data.</p>
                        <p className="text-md text-default-500">Find, verify, and confirm any account at scale.</p>
                    </div>

                    <div className="mt-4 space-x-2">
                        <Button color="primary">Pricing</Button>
                        <Button variant="light">Try It Free*</Button>
                    </div>
                </div> */}

                {/* <p className="text-xs text-default-500 mt-4">*Subject to advanced screening to prevent abuse.</p>
                
                <div className="mt-28">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        <Card 
                            className="w-full" 
                            as={Link}
                            href="https://api.oz.xyz/authentication"
                            isExternal={true}
                        >
                            <CardBody className="">
                                <div className="flex flex-col">
                                    <div className="flex flex-row justify-between">
                                        <p className="text-xl">Getting Started</p>
                                        <span className="">{'↗'}</span>
                                    </div>
                                    <p className="text-sm text-default-500">Discover how to obtain an API key and make your first request.</p>
                                </div>
                            </CardBody>
                        </Card>

                        <Card 
                            className="w-full" 
                            as={Link}
                            href="https://api.oz.xyz/credit-values"
                            isExternal={true}
                        >
                            <CardBody className="">
                                <div className="flex flex-col">
                                    <div className="flex flex-row justify-between">
                                        <p className="text-xl">Credit Values</p>
                                        <span className="">{'↗'}</span>
                                    </div>
                                    <p className="text-sm text-default-500">View the cost per successful request for each endpoint.</p>
                                </div>
                            </CardBody>
                        </Card>

                        <Card 
                            className="w-full" 
                            as={Link}
                            href="https://api.oz.xyz/api-reference"
                            isExternal={true}
                        >
                            <CardBody className="">
                                <div className="flex flex-col">
                                    <div className="flex flex-row justify-between">
                                        <p className="text-xl">API Reference</p>
                                        <span className="">{'↗'}</span>
                                    </div>
                                    <p className="text-sm text-default-500">View the API reference documentation.</p>
                                </div>
                            </CardBody>
                        </Card>

                        <Card 
                            className="w-full" 
                            as={Link}
                            href="https://t.co/KiaAdQq7hV"
                            isExternal={true}
                        >
                            <CardBody className="">
                                <div className="flex flex-col">
                                    <div className="flex flex-row justify-between">
                                        <p className="text-xl">Issues</p>
                                        <span className="">{'↗'}</span>
                                    </div>
                                    <p className="text-sm text-default-500">Report a bug, issues you've encountered, or get answers to any questions you might have.</p>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div> */}

                <div className="w-full flex items-center justify-center mt-28">
                    <div className="flex-grow">
                        <Divider />
                    </div>
                    <div>
                        <Image
                            alt="divider image"
                            height={40}
                            radius="sm"
                            src="/landing_page_images/Platonic_1_-_Octa0001.png"
                            // src="/Gon_4_Standing0001.png"
                            // src="/Gon_4_Standing0000.png"
                            // src="/Gon_3_Down_Standing0000.png"
                            width={40}
                        />
                    </div>
                    <div className="flex-grow">
                        <Divider />
                    </div>
                </div>

                <div className="mt-28">
                    <div>
                        {/* <p className="text-5xl">Access Information.</p>
                        <p className="text-5xl">Whenever.</p> */}
                        {/* <p className="text-5xl">Made to Serve Customers.</p> */}

                        {/* <p className="text-5xl">Performance,</p>
                        <p className="text-5xl">at a Glance.</p> */}

                        <p className="text-5xl">At a Glance.</p>
                    </div>

                    <div className="mt-4">
                        {/* <div>
                            <p className="text-5xl">By the</p>
                            <p className="text-5xl">Numbers.</p>
                        </div> */}

                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
                            <Card className="w-full bg-background border-divider border-small">
                                <CardBody className="">
                                    <div className="flex flex-col">
                                        {/* <p className="text-xl">Requests Made</p>
                                        <p className="text-small text-default-500">500,000</p> */}
                                        <p className="text-xl">4.2M+</p>
                                        <p className="text-sm text-default-500">Requests Made</p>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="w-full bg-background border-divider border-small">
                                <CardBody className="">
                                    <div className="flex flex-col">
                                        {/* <p className="text-xl">Rows of Data Served</p>
                                        <p className="text-small text-default-500">400,000,000</p> */}
                                        {/* <p className="text-xl">292M+</p> */}
                                        <p className="text-xl">1.8B+</p>
                                        <p className="text-sm text-default-500">Rows of Data Served</p>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="w-full bg-background border-divider border-small">
                                <CardBody className="">
                                    <div className="flex flex-col">
                                        {/* <p className="text-xl">Unique Account Coverage</p>
                                        <p className="text-small text-default-500">132,000,000</p> */}
                                        <p className="text-xl">137M+</p>
                                        <p className="text-sm text-default-500">Unique Accounts Covered</p>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="w-full bg-background border-divider border-small">
                                <CardBody className="">
                                    <div className="flex flex-col">
                                        {/* <p className="text-xl">Time To Delivery</p>
                                        <p className="text-small text-default-500">0.01 MS</p> */}
                                        <p className="text-xl">200 MS</p>
                                        <p className="text-sm text-default-500">Average Time To Delivery</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                            <Card className="w-full bg-background border-divider border-small">
                                <div className="pl-2 pt-2">
                                    <Image
                                        alt="nextui logo"
                                        height={40}
                                        radius="sm"
                                        // src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                        src="/landing_page_images/Sphere_Low_Poly_2_0000.png"
                                        width={40}
                                    />
                                </div>
                                <CardBody className="">
                                    <div className="flex flex-col">
                                        <p className="text-xl">Battle-Tested Infrastructure</p>
                                        <p className="text-small text-default-500">Rely on a platform that has been rigorously tested, ensuring you receive the data you need when you need it.</p>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="w-full bg-background border-divider border-small">
                                <div className="pl-2 pt-2">
                                    <Image
                                        alt="nextui logo"
                                        height={40}
                                        radius="sm"
                                        // src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                        src="/landing_page_images/Sphere_Matrix_x3_0000.png"
                                        width={40}
                                    />
                                </div>
                                <CardBody className="">
                                    <div className="flex flex-col">
                                        <p className="text-xl">Wide Coverage</p>
                                        <p className="text-small text-default-500">Access the most in-depth graph covering all of Crypto Twitter.</p>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="w-full bg-background border-divider border-small">
                                <div className="pl-2 pt-2">
                                    <Image
                                        alt="nextui logo"
                                        height={40}
                                        radius="sm"
                                        // src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                        src="/landing_page_images/Sphere_Hourglass0001.png"
                                        width={40}
                                    />
                                </div>
                                <CardBody className="">
                                    <div className="flex flex-col">
                                        <p className="text-xl">Fast Delivery</p>
                                        <p className="text-small text-default-500">Receive data at lightning speeds, faster than any competitor.</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>

                    <div className="mt-28">
                        <Card className="w-full bg-background">
                            <div className="flex justify-center">
                                <img src="/landing_page_images/eye.svg" height={100} width={100} />
                            </div>
                            <CardBody className="text-center">
                                <div className="flex flex-col max-w-md mx-auto">
                                    <p className="text-xl">The All-Seeing Eye</p>
                                    <p className="text-sm text-default-500">Get a glimpse into the ever-changing social sphere, powered by the most comprehensive graph available.</p>
                                    {/* <p className="text-sm text-default-500">Built atop the largest manually labeled corpus of text in the field, TOTO is a machine learning powerhouse, continually enhanced by positive feedback loops from the members of <Link className="text-sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://oz.xyz">oz_dao</Link>.</p> */}
                                </div>
                            </CardBody>
                        </Card>
                    </div>

                    {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                        <Card className="w-full bg-background border-divider border-small">
                            <div className="pl-2 pt-2">
                                <Image
                                    alt="nextui logo"
                                    height={40}
                                    radius="sm"
                                    // src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                    src="/landing_page_images/Sphere_Low_Poly_2_0000.png"
                                    width={40}
                                />
                            </div>
                            <CardBody className="">
                                <div className="flex flex-col">
                                    <p className="text-xl">Battle-Tested Infrastructure</p>
                                    <p className="text-small text-default-500">Rely on a platform that has been rigorously tested, ensuring you receive the data you need when you need it.</p>
                                </div>
                            </CardBody>
                        </Card>

                        <Card className="w-full bg-background border-divider border-small">
                            <div className="pl-2 pt-2">
                                <Image
                                    alt="nextui logo"
                                    height={40}
                                    radius="sm"
                                    // src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                    src="/landing_page_images/Sphere_Matrix_x3_0000.png"
                                    width={40}
                                />
                            </div>
                            <CardBody className="">
                                <div className="flex flex-col">
                                    <p className="text-xl">Wide Coverage</p>
                                    <p className="text-small text-default-500">Access the most in-depth graph covering all of Crypto Twitter.</p>
                                </div>
                            </CardBody>
                        </Card>

                        <Card className="w-full bg-background border-divider border-small">
                            <div className="pl-2 pt-2">
                                <Image
                                    alt="nextui logo"
                                    height={40}
                                    radius="sm"
                                    // src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                    src="/landing_page_images/Sphere_Hourglass0001.png"
                                    width={40}
                                />
                            </div>
                            <CardBody className="">
                                <div className="flex flex-col">
                                    <p className="text-xl">Fast Delivery</p>
                                    <p className="text-small text-default-500">Receive data at lightning speeds, faster than any competitor.</p>
                                </div>
                            </CardBody>
                        </Card>
                    </div> */}

                    {/* <div className="mt-4">
                        <div className="grid grid-cols-1 mt-4">
                            <Card className="w-full bg-background border-divider border-small">
                                <CardBody>
                                    <div className="flex flex-col">
                                        <div>
                                            <p className="text-xl">The All-Seeing Eye</p>
                                            <p className="text-sm text-default-500">Get a glimpse into the ever-changing social sphere, powered by the most comprehensive graph available.</p>
                                            <br />
                                            <p className="text-sm text-default-500">Built atop the largest manually labeled corpus of text in the field, TOTO is a machine learning powerhouse, continually enhanced by positive feedback loops from the members of <Link className="text-sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://oz.xyz">oz_dao</Link>.</p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div> */}
                </div>

                <div className="mt-28">
                    <div>
                        <p className="text-5xl">Features.</p>
                        {/* <p className="text-5xl">Whenever.</p> */}
                        <p className="text-sm text-default-500 max-w-lg">Built atop the largest manually labeled corpus of text in the field, TOTO is a machine learning powerhouse, continually enhanced by positive feedback loops from the members of <Link className="text-sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://oz.xyz">oz_dao</Link>.</p>
                    </div>

                    <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                            <Card className="w-full bg-background border-divider border-small">
                                <div className="pl-2 pt-2">
                                    <Image
                                        alt="nextui logo"
                                        height={40}
                                        radius="sm"
                                        // src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                        src="/landing_page_images/Tube_Medium_Laying_4_0000.png"
                                        width={40}
                                    />
                                </div>
                                <CardBody className="">
                                    <div className="flex flex-col">
                                        {/* <p className="text-xl">Filtration</p>
                                        <p className="text-small text-default-500">
                                            Explore the latest metadata and track the historical usernames of an account, revealing insights into social {'<>'} on-chain data relationships.
                                        </p> */}
                                        <p className="text-xl">Scoring</p>
                                        <p className="text-small text-default-500">Assess the reputation of social accounts using our proprietary scoring algorithm derived from the Crypto Twitter graph.</p>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="w-full bg-background border-divider border-small">
                                <div className="pl-2 pt-2">
                                    <Image
                                        alt="nextui logo"
                                        height={40}
                                        radius="sm"
                                        // src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                        src="/landing_page_images/Sphere_Low_Poly_1_0000.png"
                                        width={40}
                                    />
                                </div>
                                <CardBody className="">
                                    <div className="flex flex-col">
                                        <p className="text-xl">Instant Data</p>
                                        <p className="text-small text-default-500">Access real-time social and on-chain data, including new followers and newly deployed contracts.</p>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className="mt-4">
                            <Card className="w-full bg-background border-divider border-small">
                                <div className="pl-2 pt-2">
                                    <Image
                                        alt="nextui logo"
                                        height={40}
                                        radius="sm"
                                        // src="https://avatars.githubusercontent.com/u/86160567?s=200&v=4"
                                        src="/landing_page_images/Sphere_Top_Cut0000.png"
                                        width={40}
                                    />
                                </div>
                                <CardBody className="">
                                    {/* <div className="flex flex-col">
                                        <p className="text-xl">Scoring</p>
                                        <p className="text-small text-default-500">Assess the reputation of social accounts from a proprietary scoring algorithm sourced from the Crypto Twitter graph.</p>
                                    </div> */}
                                    <p className="text-xl">Filtration</p>
                                    <p className="text-small text-default-500">
                                        Explore the latest metadata and track the historical usernames of an account, revealing insights into social {'<>'} on-chain data relationships.
                                    </p>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="w-full flex items-center justify-center mt-28">
                    <div className="flex-grow">
                        <Divider />
                    </div>
                    <Image
                        alt="divider image"
                        height={40}
                        radius="sm"
                        src="/landing_page_images/Gon_4_Standing0001.png"
                        width={40}
                    />
                    <div className="flex-grow">
                        <Divider />
                    </div>
                    <Image
                        alt="Third Image"
                        height={40}
                        radius="sm"
                        src="/landing_page_images/Gon_4_Standing0000.png"
                        width={40}
                    />
                    <div className="flex-grow">
                        <Divider />
                    </div>
                </div>

                {/* <div className="mt-28">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            data when you want
                            <p className="text-default-500">query endpoints any time</p>
                        </div>
                        <div>
                            instant notifications
                            <p className="text-default-500">webhooks</p>
                        </div>
                        <div>
                            real time / historical data
                            <p className="text-default-500">...</p>
                        </div>
                    </div>
                </div>

                <div className="mt-28">
                    Everyone Loves Toto

                    <p>user praise</p>
                </div>

                <div className="mt-28">
                    casestudies (coming soon)
                </div> */}

                <div className="mt-28">
                    <div className="">
                        <p className="text-5xl">Frequently Asked</p>
                        <p className="text-5xl">Questions.</p>
                    </div>

                    <div className="mt-4">
                        <Faq />
                    </div>
                </div>

                {/* <div className="mt-28">
                    <Card className="w-full h-[170px] col-span-12 sm:col-span-7">
                        <CardHeader className="absolute z-10 top-1 flex-col items-start">
                            <h4 className="text-white/90 font-medium text-5xl">Ready to</h4>
                            <h4 className="text-white/90 font-medium text-5xl">get started?</h4>
                        </CardHeader>
                        <Image
                            removeWrapper
                            alt="Relaxing app background"
                            className="z-0 w-full h-full object-cover"
                            // src="/rectangle2.png"
                            // src="/rectangle7.png"
                            src="/rectangle8.png"
                        />
                        <CardFooter className="absolute bottom-0 z-10 flex flex-row space-x-2 justify-between w-full sm:w-auto">
                            <Button as={Link} href="/pricing" color="secondary" size="sm" className="w-full sm:w-auto">Pricing</Button>
                            <Button
                                href="https://api.oz.xyz"
                                as={Link}
                                // color="secondary"
                                variant="light"
                                showAnchorIcon
                                isExternal={true}
                                size="sm"
                                className="w-full sm:w-auto"
                                anchorIcon={' ↗'}
                            >Documentation</Button>
                        </CardFooter>
                    </Card>
                </div> */}

                {/* <Card className="bg-background bg-no-repeat bg-cover mt-28" style={{ backgroundImage: 'url("/noisy-gradients.png")' }}> */}
                {/* <Card className="bg-background bg-no-repeat bg-cover mt-28" style={{ backgroundImage: 'url("/landing_page_images/noisy-gradients0.png")' }}> */}
                <Card className="bg-background border-small border-divider mt-28">
                {/* <Card className="bg-background mt-28"> */}
                    <CardHeader className="flex flex-col items-start">
                        <p className="text-4xl sm:text-5xl">Looking to build with Toto?</p>
                    </CardHeader>
                    <CardBody>
                        <p className="text-md text-default-500 w-full sm:max-w-md">
                            Learn how you can leverage our underlying technology to bring instant social {'<>'} onchain data to your app or project.
                        </p>
                    </CardBody>
                    <CardFooter>
                        <div className="w-full space-y-2 sm:space-y-0 sm:space-x-2">
                            {/* <Button as={Link}  href="/login" color="primary" className="w-full sm:w-auto">Get Started for Free</Button> */}
                            <Button 
                                as={Link} 
                                href="https://t.me/babushka_slayer" 
                                color="primary" 
                                className="w-full sm:w-auto"
                                isExternal={true}
                                showAnchorIcon={true}
                                anchorIcon={' ↗'}
                            >
                                Contact Us
                            </Button>
                            {/* <Button as={Link}  href="/pricing" variant="flat" className="w-full sm:w-auto">View Pricing</Button> */}
                            <Button 
                                as={Link} 
                                href="https://docs.totoml.app" 
                                // color="primary" 
                                variant="flat"
                                className="w-full sm:w-auto"
                                isExternal={true}
                                showAnchorIcon={true}
                                anchorIcon={' ↗'}
                            >
                                Documentation
                            </Button>
                        </div>
                    </CardFooter>
                </Card>

                {/* <div className="mt-28 relative overflow-hidden bg-no-repeat bg-cover rounded-large" 
                    style={{ backgroundImage: 'url("/landing_page_images/rectangle8.png")'}}>
                    <div className="relative z-10 p-4">
                        <h1 className="text-5xl">Want to Build</h1>
                        <h1 className="text-5xl">With Toto?</h1>

                        <div className="mt-4">
                            <p className="text-md text-default-500 w-full sm:max-w-md">
                                Learn how you can leverage our underlying technology to bring instant social {'<>'} onchain data to your app or project.
                            </p>
                        </div>

                        <div className="mt-4">
                            <Button 
                                as={Link} 
                                href="https://t.me/babushka_slayer" 
                                color="secondary" 
                                size="sm" 
                                className="w-full sm:w-auto"
                                isExternal={true}
                                showAnchorIcon={true}
                                anchorIcon={' ↗'}
                            >
                                    Contact Us
                            </Button>
                        </div>
                    </div>
                </div> */}

                <div className="w-full flex items-center justify-center mt-28">
                    <div className="flex-grow">
                        <Divider />
                    </div>
                    <Image
                        alt="divider image"
                        height={40}
                        radius="sm"
                        src="/landing_page_images/Gon_3_Down_Laying0000.png"
                        width={40}
                    />
                    <div className="flex-grow">
                        <Divider />
                    </div>
                    <Image
                        alt="Third Image"
                        height={40}
                        radius="sm"
                        src="/landing_page_images/Gon_3_Down_Laying0002.png"
                        width={40}
                    />
                    <div className="flex-grow">
                        <Divider />
                    </div>
                    <Image
                        alt="Third Image"
                        height={40}
                        radius="sm"
                        src="/landing_page_images/Gon_3_Down_Laying0001.png"
                        width={40}
                    />
                    <div className="flex-grow">
                        <Divider />
                    </div>
                </div>

                <div className="mt-4">
                    <Footer />
                </div>
            </div>
        </>
    )
}
